<template>
  <div class="header d-flex justify-content-end">
    <div class="avatar d-flex align-items-center px-4">
      <img :src="logo" alt="avatar" v-on:click.prevent="setShowSettings()" />
    </div>

    <div class="setting-user" v-if="showSetting">
      <div class="logout">
        <b-button variant="light" v-on:click="logoutBtn()">ログアウト</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/tree.png";
import { mapActions } from "vuex";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
export default {
  name: "Header",
  data() {
    return {
      logo: logo,
      showSetting: false,
    };
  },
  methods: {
    ...mapActions({ logout: "logout" }),
    setShowSettings() {
      this.showSetting = !this.showSetting;
    },
    logoutBtn() {
      Api.cdeaRequestServer
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success("ログアウトされました");
            localStorage.removeItem(Constants.TOKEN_CDEA);
            localStorage.removeItem(Constants.EXPIRES_AT_CDEA);
            localStorage.removeItem(Constants.IS_REMEMBER_CDEA);
            this.$router.push({
              name: "login cdea",
            });
          } else {
            this.$toasted.error(data.message);
          }
        })
        .catch((error) => {
          this.$toasted.error(error.message);
        });
    },
  },
};
</script>

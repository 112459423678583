<template>
  <div class="layout-master">
    <div class="layout-master-content">
      <Header />
      <div class="main" :class="{ active: isActive }">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <Footer :isActive="isActive" />
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleSidebar(data) {
      this.isActive = data;
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "@/views/master/master.scss";
</style>

<template>
  <div class="footer" :class="{ active: isActive }">
    <div class="copyright">Copyright© 2022</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isActive: Boolean,
  },
};
</script>
